/**

		- Cursors

**/
.cursor-pointer {
	cursor: pointer;
}
.cursor-text {
	cursor: text;
}

.sortable-handle,
.cursor-move {
	cursor: move;
	cursor: -webkit-grabbing;
}
