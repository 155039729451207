// -- -- -- -- -- -- -- -- --
// Widths
// -- -- -- -- -- -- -- -- --
// required by text-truncate in some situations
.mw-0, .min-w-0 { min-width: 0 !important; }
.mw-75 { max-width: 75% !important; }
.mw-50 { max-width: 50% !important; }
.mw-25 { max-width: 25% !important; }

.w-100 { width: 100px; }
.w-150 { width: 150px; }

.w-200 { width: 200px; }
.w-250 { width: 250px; }

.w-300 { width: 300px; }
.w-350 { width: 350px; }

.w-400 { width: 400px; }
.w-450 { width: 450px; }

.w-500 { width: 500px; }
.w-550 { width: 550px; }

.w-600 { width: 600px; }
.w-650 { width: 650px; }


.max-w-100 { max-width: 100px !important; }
.max-w-150 { max-width: 150px !important; }

.max-w-200 { max-width: 200px !important; }
.max-w-250 { max-width: 250px !important; }

.max-w-300 { max-width: 300px !important; }
.max-w-350 { max-width: 350px !important; }

.max-w-400 { max-width: 400px !important; }
.max-w-450 { max-width: 450px !important; }

.max-w-500 { max-width: 500px !important; }
.max-w-550 { max-width: 550px !important; }

.max-w-600 { max-width: 600px !important; }
.max-w-650 { max-width: 650px !important; }

.max-w-700 { max-width: 700px !important; }
.max-w-750 { max-width: 750px !important; }

.max-w-800 { max-width: 800px !important; }
