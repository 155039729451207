// -- -- -- -- -- -- -- -- --
// Print Fixes
// -- -- -- -- -- -- -- -- --
@media print {

  // -- -- -- -- -- -- -- -- --
  // Chrome
  // hide header/footer
  // -- -- -- -- -- -- -- -- --
     @page { margin:0 -6cm; }
  // -- -- -- -- -- -- -- -- --


  // -- -- -- -- -- -- -- -- --
  // Grid
  // -- -- -- -- -- -- -- -- --
  .row > * { float: left; }
  .col-sm-12, .col-md-12, .col-lg-12, .col-xl-12    { width: 100%; }
  .col-sm-9, .col-md-9, .col-lg-9, .col-xl-9        { width: 75%; }
  .col-sm-8, .col-md-8, .col-lg-8, .col-xl-8        { width: 66.66666667%; }
  .col-sm-7, .col-md-7, .col-lg-7, .col-xl-7        { width: 58.33333333%;}
  .col-sm-6, .col-md-6, .col-lg-6, .col-xl-6        { width: 50%;}
  .col-sm-4, .col-md-4, .col-lg-4, .col-xl-4        { width: 33.33333333%; }
  .col-sm-3, .col-md-3, .col-lg-3, .col-xl-3        { width: 25%; }


  // -- -- -- -- -- -- -- -- --
  // Text align
  // -- -- -- -- -- -- -- -- --
  .text-xl-start, .text-lg-start,
  .text-md-start, .text-sm-start {
    @if $global-rtl-enable == false {
        text-align: left !important;
    } @else {
        text-align: right !important;
    }
  }
  .text-xl-end, .text-lg-end,
  .text-md-end, .text-sm-end {
    @if $global-rtl-enable == false {
        text-align: right !important;
    } @else {
        text-align: left !important;
    }
  }


  // -- -- -- -- -- -- -- -- --
  // Utils
  // -- -- -- -- -- -- -- -- --
  .hide-print {
    display: none !important;
    visibility: hidden !important;
  }
  .shadow-none-print {
    box-shadow: none !important;
  }
  .border-none-print {
  	border: 0 !important;
  }
  .padding-0-print {
  	padding: 0 !important;
  }

}