/**

		- Positions 				.absolute-full, .fixed-full
									.top-0, .right-0, .bottom-0, .left-0

**/
.absolute-full {
	position: absolute !important;
	width: 100%; height: 100%;
	top:0; right: 0; bottom: 0; left: 0;
}
.fixed-full {
	position: fixed !important;
	width: 100%; height: 100%;
	top:0; right: 0; bottom: 0; left: 0;
}
.top-0 {
	top: 0 !important;
}
.top-50 {
	top: 50% !important;
}
.bottom-0 {
	bottom: 0 !important;
}
.left-0 {
	left: 0 !important;
	right: auto; // do not use !important
}
.left-50 {
	left: 50% !important;
	right: auto; // do not use !important
}
.right-0 {
	right: 0 !important;
	left: auto; // do not use !important
}
.right-50 {
	right: 50% !important;
	right: auto; // do not use !important
}
.start-0 {
	@if $global-rtl-enable == false {
		left: 0 !important;
		right: auto !important;
	} @else {
		right: 0 !important;
		left: auto !important;
	}	
}
.end-0 {
	@if $global-rtl-enable == false {
		right: 0 !important;
		left: auto !important;
	} @else {
		left: 0 !important;
		right: auto !important;
	}	
}

.start-auto {
	@if $global-rtl-enable == false {
		left: auto !important;
	} @else {
		right: auto !important;
	}	
}
.end-auto {
	@if $global-rtl-enable == false {
		right: auto !important;
	} @else {
		left: auto !important;
	}	
}


/* out of viewport */
.viewport-out {
	position: fixed !important;
	left: -9999px !important;
	top: -9999px !important;
}

// required
// position-relative 
// position-absolute 
// position-fixed 
.valign-middle,
.y-middle {
	position: relative;
	top: 50%;
	-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
}
.x-middle {
	position: relative;
	top: 50%;
	-webkit-transform: translateX(-50%);
			transform: translateX(-50%);
}


/* better than .valign-middle */
.d-middle {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;

	-webkit-box-pack: center;
	-ms-flex-pack: center;

	-webkit-justify-content: center;
	justify-content: center;

	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
}



