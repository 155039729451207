/*

  Viewport

*/
@each $val in $util-viewport-val {

  .vw-#{$val} { width: #{$val}vw !important; }
  .vh-#{$val} { height: #{$val}vh !important; }

  .min-vw-#{$val} { min-width: #{$val}vw !important; }
  .max-vw-#{$val} { max-width: #{$val}vw !important; }

  .min-vh-#{$val} { min-height: #{$val}vh !important; }
  .max-vh-#{$val} { min-height: #{$val}vh !important; }

}

// XL
@media only screen and (min-width: map-get($grid-breakpoints, "xl") - 1) {

  @each $val in $util-viewport-val {

    .vh-xl-#{$val} { height: #{$val}vh !important; }
    .min-vh-xl-#{$val} { min-height: #{$val}vh !important; }
    .max-vh-xl-#{$val} { min-height: #{$val}vh !important; }

  }

}


// LG
@media only screen and (min-width: map-get($grid-breakpoints, "lg") - 1) {

  @each $val in $util-viewport-val {

    .vh-lg-#{$val} { height: #{$val}vh !important; }
    .min-vh-lg-#{$val} { min-height: #{$val}vh !important; }
    .max-vh-lg-#{$val} { min-height: #{$val}vh !important; }

  }

}


// MD
@media only screen and (max-width: map-get($grid-breakpoints, "lg") - 1) and (min-width: map-get($grid-breakpoints, "md")) {

  @each $val in $util-viewport-val {

    .vh-md-#{$val} { height: #{$val}vh !important; }
    .min-vh-md-#{$val} { min-height: #{$val}vh !important; }
    .max-vh-md-#{$val} { min-height: #{$val}vh !important; }

  }

}



// SM
@media only screen and (min-width: map-get($grid-breakpoints, "sm")) {

  @each $val in $util-viewport-val {

    .vh-sm-#{$val} { height: #{$val}vh !important; }
    .min-vh-sm-#{$val} { min-height: #{$val}vh !important; }
    .max-vh-sm-#{$val} { min-height: #{$val}vh !important; }

  }

}