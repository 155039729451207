.card {
  box-shadow: $box-shadow-soft;
}
  .card hr {
    background-color: $border-color;
    opacity: 1;
  }

// Removed in bs5
// https://getbootstrap.com/docs/4.3/components/card/#card-columns
.card-columns {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 1.25rem;
  -moz-column-gap: 1.25rem;
  column-gap: 1.25rem;
  orphans: 1;
  widows: 1;
}
.card-columns .card {
  display: inline-block;
  width: 100%;
}
.card-columns .card {
  margin-bottom: 0.75rem;
}
.card-columns .card img {
  width: 100%;
}

.card-columns {
  @include media-breakpoint-only(xs) {
    column-count: 2;
  }
  @include media-breakpoint-only(lg) {
    column-count: 3;
  }
  @include media-breakpoint-only(xl) {
    column-count: 4;
  }
}
